import React, { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { IoMail, IoCall, IoSend, IoCheckmarkCircle } from "react-icons/io5";
import "./contact.css";
import { TYPOGRAPHY } from "../shared/Typography";

const Section = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem;
  background: transparent;
`;

const FormContainer = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 20px rgba(0, 13, 26, 0.08);
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    grid-template-columns: 380px 1fr;
  }
`;

const ContactInfo = styled.div`
  background: #000d1a;
  padding: 2.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding: 3rem;
  }
`;

const Title = styled.h2`
  font-size: ${TYPOGRAPHY.h2.size};
  font-weight: ${TYPOGRAPHY.h2.weight};
  line-height: ${TYPOGRAPHY.h2.lineHeight};
  margin-bottom: 2rem;
`;

const ContactMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: auto;
`;

const ContactMethod = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateX(8px);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
`;

const ContactDetails = styled.div`
  h4 {
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: rgba(255, 255, 255, 0.9);
  }

  p {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.75);
  }
`;

const ContactMessage = styled.p`
  margin-top: 3rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  line-height: 1.6;
`;

const FormWrapper = styled.div`
  padding: 2.5rem;
  background: white;
  box-shadow: inset 4px 0 12px -6px rgba(0, 13, 26, 0.1);

  @media screen and (min-width: 768px) {
    padding: 3rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: ${TYPOGRAPHY.small.size};
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 13, 26, 0.2);
  border-radius: 6px;
  font-size: ${TYPOGRAPHY.body.size};
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #000d1a;
    box-shadow: 0 0 0 2px rgba(0, 13, 26, 0.1);
  }

  &::placeholder {
    color: rgba(0, 13, 26, 0.4);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 13, 26, 0.2);
  border-radius: 6px;
  font-size: ${TYPOGRAPHY.body.size};
  min-height: 120px;
  resize: vertical;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #000d1a;
    box-shadow: 0 0 0 2px rgba(0, 13, 26, 0.1);
  }

  &::placeholder {
    color: rgba(0, 13, 26, 0.4);
  }
`;

const SubmitButton = styled.button`
  background: #000d1a;
  color: white;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;

  &:hover {
    background: rgba(0, 13, 26, 0.9);
    transform: translateY(-1px);
  }

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #10b981;
  font-weight: 500;
  margin-top: 1rem;
  animation: fadeIn 0.4s ease-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Contact = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const result = await emailjs.sendForm(
        "Gmail",
        "template_iw3ztkp",
        form.current,
        "kpxEvPGPbkfaSjI2D"
      );
      console.log("Email envoyé avec succès");
      setSubmitSuccess(true);
      form.current?.reset();
    } catch (error) {
      console.error(error.text);
      alert("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section name="contact" id="contact">
      <FormContainer>
        <ContactInfo>
          <div>
            <Title>Parlons de vos objectifs</Title>
            <ContactMethods>
              <ContactMethod>
                <IconWrapper>
                  <IoCall />
                </IconWrapper>
                <ContactDetails>
                  <h4>Téléphone</h4>
                  <p>06 13 48 95 58</p>
                </ContactDetails>
              </ContactMethod>
              <ContactMethod>
                <IconWrapper>
                  <IoMail />
                </IconWrapper>
                <ContactDetails>
                  <h4>Email</h4>
                  <p>benjaminbrusset@yahoo.fr</p>
                </ContactDetails>
              </ContactMethod>
            </ContactMethods>
          </div>
          <ContactMessage>
            Prêt à transformer vos objectifs en réalité ? Contactez-moi dès
            maintenant pour commencer votre parcours vers le succès.
          </ContactMessage>
        </ContactInfo>

        <FormWrapper>
          <StyledForm ref={form} onSubmit={sendEmail}>
            <FormGroup>
              <Label htmlFor="nom">Nom et prénom</Label>
              <Input
                id="nom"
                type="text"
                name="nom"
                placeholder="Votre nom complet"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                name="email"
                placeholder="Votre adresse email"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="subject">Sujet</Label>
              <Input
                id="subject"
                type="text"
                name="subject"
                placeholder="Le sujet de votre message"
                required
              />
            </FormGroup>

            <FormGroup>
              <Label htmlFor="message">Message</Label>
              <TextArea
                id="message"
                name="message"
                placeholder="Parlez-moi de vos objectifs..."
                required
              />
            </FormGroup>

            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                "Envoi en cours..."
              ) : (
                <>
                  <IoSend /> Envoyer mon message
                </>
              )}
            </SubmitButton>

            {submitSuccess && (
              <SuccessMessage>
                <IoCheckmarkCircle />
                <span>Message envoyé avec succès !</span>
              </SuccessMessage>
            )}
          </StyledForm>
        </FormWrapper>
      </FormContainer>
    </Section>
  );
};

export default Contact;
