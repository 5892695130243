import ImageOne from "../images/house-1.jpg";
import ImageTwo from "../images/house-2.jpg";
import ImageThree from "../images/house-3.jpg";
import ImageFour from "../images/house-4.jpg";

export const SliderData = [
  {
    title: "Coaching Ben",
    datacoach1: "• Votre coach santé, performance et compétition",
    datacoach2: "• Conseils nutritionnels",
    datacoach3: "• Suivi personnalisé",
    datacoach4: "• Sur Orange(84), Carpentras(84) et alentours",
    path: "cours1",
    label: "Qui suis-je ?",
    image: ImageTwo,
    alt: "Gym",
  },
  {
    title: "Coaching Ben",
    datacoach1: "• Coach sportif à domicile",
    datacoach2: "",
    datacoach3: "• Entraineur de natation et triathlon",
    datacoach4: "• Spécialisé en remise en forme",
    path: "cours1",
    label: "Qui suis-je ?",
    image: ImageOne,
    alt: "Gym",
  },
  {
    title: "Coaching Ben",
    datacoach1: "• Musculation",
    datacoach2: "• Haltérophilie",
    datacoach3: "• Powerlifting",
    datacoach4: "• Natation et Triathlon",
    path: "cours1",
    label: "Qui suis-je ?",
    image: ImageThree,
    alt: "Gym",
  },
  {
    title: "Le mot du coach :",
    datacoach1: "",
    datacoach2:
      "« Le seul mauvais entraînement c’est celui que vous ne faites pas. »",
    datacoach3: "",
    datacoach4: "",
    path: "cours1",
    label: "Qui suis-je ?",
    image: ImageFour,
    alt: "Gym",
  },
];
