import React from "react";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Listings from "../components/Listings";
import { InfoData, InfoDataTwo } from "../data/InfoData";
import { SliderData } from "../data/SliderData";
import InfoSection from "../components/InfoSection";
import InfoSection2 from "../components/InfoSection2";
import { InfoDataThree } from "../data/InfoData2";
import InfoSection3 from "../components/InfoSection3";
import { InfoDataFour } from "../data/InfoData3";
import Contact from "../components/Contact";

const Home = () => {
  return (
    <>
      <Hero slides={SliderData} />
      <InfoSection {...InfoData} />
      <InfoSection {...InfoDataTwo} />
      <InfoSection2 {...InfoDataThree} />
      <Features />
      <Listings />
      <InfoSection3 {...InfoDataFour} />
      <Contact />
    </>
  );
};

export default Home;
