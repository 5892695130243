import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
  }

  html, body {
    overflow-x: hidden;
  }

  /* Styles pour la barre de défilement */
  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
    border-radius: 20px;
  }
`;

export default GlobalStyle;
