import ImageOne from "../images/infosport1.jpg";
import ImageTwo from "../images/infosport2.jpg";

export const InfoData = {
  heading: "Mon objectif, vous faire atteindre les vôtres",
  paragraphOne:
    "Je m'appelle Benjamin Brusset, je suis préparateur physique aux alentours de Carpentras et Orange. Le coaching a toujours été une évidence pour moi, c'est pourquoi j'ai étudié la préparation et l'entraînement sportif.",
  paragraphTwo:
    "J'ai obtenu une licence STAPS entraînement sportif option natation et également un titre de maître nageur sauveteur. En troisième année de Fac à Montpellier j'ai participé au parcours de force ce qui m'a permis d'obtenir mes brevets fédéraux de niveaux 2 en force athlétique et haltérophilie.",
  paragraphThree:
    "Ce cursus universitaire m'a appris à m'adapter à toutes personnes et aux caractéristiques qui lui sont associées pour atteindre tous les objectifs possibles.",
  buttonLabel: "Pour qui ?",
  image: ImageOne,
  reverse: false,
  delay: 100,
  name: "cours1",
  goto: "cours2",
};

export const InfoDataTwo = {
  heading: "Le sport ne forge pas le caractère, il le relève",
  paragraphOne:
    "J'adapte mes coaching à tout le monde : d'une personne n'ayant jamais fait de sport jusqu'au grand compétiteur, je m'adapte également à toutes pathologies et même aux femmes enceintes.",
  paragraphTwo:
    "Je vous suivrai à domicile, dans une salle de sport, ou à distance en vidéoconférence : fini les excuses ! Les programmes que je propose peuvent être orientés sur courte, moyenne ou longue durée.",
  paragraphThree:
    "Je travaille aussi auprès des entreprises pour du Team Building, afin de veiller au maintien de la forme des employés, et travailler sur de la prévention de blessures pour les personnes soulevant des charges lourdes ou à répétition.",
  buttonLabel: "Pourquoi ?",
  image: ImageTwo,
  reverse: true,
  delay: 300,
  name: "cours2",
  goto: "cours3",
};
