import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import ImageOne from "../images/work1.jpg";
import { Button } from "./Button";
import { TYPOGRAPHY } from "../shared/Typography";

const Section = styled.section`
  background: #fff;
  padding: 8rem 0;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: repeating-linear-gradient(
      45deg,
      rgba(0, 13, 26, 0.03) 0px,
      rgba(0, 13, 26, 0.03) 10px,
      transparent 10px,
      transparent 20px
    );
    mask-image: linear-gradient(to bottom, transparent, black);
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;

const ContentWrapper = styled(motion.div)`
  flex: 1;
  z-index: 2;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const Content = styled.div`
  h1 {
    font-size: ${TYPOGRAPHY.h1.size};
    font-weight: ${TYPOGRAPHY.h1.weight};
    line-height: ${TYPOGRAPHY.h1.lineHeight};
    margin-bottom: 2rem;
    text-transform: uppercase;
    background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: ${TYPOGRAPHY.body.size};
    line-height: ${TYPOGRAPHY.body.lineHeight};
    margin-bottom: 1.5rem;
    color: #333;

    strong {
      color: #2f6aa4;
    }
  }
`;

const ImageWrapper = styled(motion.div)`
  flex: 1;
  height: 600px;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 20px 20px 60px rgba(0, 13, 26, 0.1);
  position: relative;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(0, 13, 26, 0.1) 100%
    );
  }

  @media screen and (max-width: 768px) {
    height: 400px;
    width: 100%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
  border-radius: 0 20px 0 20px;
  padding: 1rem 3rem;
  font-size: 1.1rem;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const Features = () => {
  return (
    <Section name="features" id="features">
      <Container>
        <ContentWrapper
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          <Content>
            <h1>Comment je travaille ?</h1>
            <p>
              Le <strong>premier rendez-vous</strong> est réservé à un
              questionnaire pour déterminer vos objectifs et être au plus proche
              de vos attentes. Chacune des séances est{" "}
              <strong>personnalisée</strong> afin d'atteindre vos objectifs en
              prenant compte de vos particularités.
            </p>
            <p>
              Le suivi est réalisé par <strong>divers tests</strong> du début de
              la prise en charge tout au long du coaching ce qui vous permettra
              de visualiser votre progression. Des{" "}
              <strong>conseils nutritionnels</strong> seront transmis également.
            </p>
            <p>
              Pour les programmations : la <strong>première séance</strong> est
              en présentiel ainsi qu'une <strong>séance mensuelle</strong> pour
              faire un bilan et le point ensemble.
            </p>
            <Link
              to="listings"
              spy={true}
              smooth={true}
              offset={-30}
              duration={500}
            >
              <StyledButton>Découvrir mes cours</StyledButton>
            </Link>
          </Content>
        </ContentWrapper>
        <ImageWrapper
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.8 }}
        >
          <StyledImage src={ImageOne} alt="Séance de coaching" />
        </ImageWrapper>
      </Container>
    </Section>
  );
};

export default Features;
