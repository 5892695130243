import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Link } from "react-scroll";
import { TYPOGRAPHY } from "../shared/Typography";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
  background: #fff;
`;

const Container = styled.div`
  padding: 2rem calc((100vw - 1300px) / 2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: ${TYPOGRAPHY.h1.size};
  font-weight: ${TYPOGRAPHY.h1.weight};
  line-height: ${TYPOGRAPHY.h1.lineHeight};
  text-align: center;
  margin-bottom: 3rem;
  text-transform: uppercase;
  background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
`;

const PriceCard = styled.div`
  background: #fff;
  border: 2px solid #2f6aa4;
  border-radius: 10px;
  padding: 2.5rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 500px;
  position: relative;
  overflow: hidden;

  &:hover {
    background: #2f6aa4;
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 12px 24px rgba(47, 106, 164, 0.2);

    h3,
    h2,
    p,
    li {
      color: #fff;
      transform: scale(1.05);
    }

    li:before {
      color: #fff;
    }

    ${ButtonWrapper} {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h3 {
    color: #000d1a;
    font-size: ${TYPOGRAPHY.h3.size};
    font-weight: ${TYPOGRAPHY.h3.weight};
    line-height: ${TYPOGRAPHY.h3.lineHeight};
    margin-bottom: 1.5rem;
    transition: all 0.4s ease;
  }
`;

const Price = styled.h2`
  font-size: ${TYPOGRAPHY.h2.size};
  font-weight: ${TYPOGRAPHY.h2.weight};
  line-height: ${TYPOGRAPHY.h2.lineHeight};
  margin-bottom: 0.5rem;
  color: #000d1a;
  transition: all 0.4s ease;
`;

const PriceSubtext = styled.p`
  font-size: ${TYPOGRAPHY.body.size};
  line-height: ${TYPOGRAPHY.body.lineHeight};
  color: #000d1a;
  text-align: center;
  transition: all 0.4s ease;
  min-height: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
  width: 100%;
  flex-grow: 1;
`;

const Feature = styled.li`
  padding: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  color: #000d1a;
  transition: all 0.4s ease;
  font-size: ${TYPOGRAPHY.body.size};
  line-height: ${TYPOGRAPHY.body.lineHeight};
  margin-left: 2rem;
  text-align: left;
  position: relative;

  &:before {
    content: "✓";
    position: absolute;
    left: -2rem;
    color: #2f6aa4;
    font-weight: bold;
    transition: all 0.4s ease;
  }
`;

const PriceHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const EmptySpace = styled.div`
  min-height: 2.5rem;
  margin-bottom: 0.5rem;
`;

const InfoSection3 = ({ heading }) => {
  const pricingData = [
    {
      sessions: "1 Séance",
      price: "45€",
      features: [
        "Séance individuelle",
        "Coaching personnalisé",
        "Suivi détaillé",
      ],
    },
    {
      sessions: "10 Séances",
      price: "400€",
      pricePerSession: "40€/séance",
      savings: "50€ d'économie",
      features: [
        "Séance individuelle",
        "Coaching personnalisé",
        "Suivi détaillé",
      ],
    },
    {
      sessions: "20 Séances",
      price: "700€",
      pricePerSession: "35€/séance",
      savings: "200€ d'économie",
      features: [
        "Séance individuelle",
        "Coaching personnalisé",
        "Suivi détaillé",
      ],
    },
  ];

  return (
    <Section name="tarifs" id="tarifs">
      <Container>
        <Title>{heading}</Title>
        <PricingGrid>
          {pricingData.map((plan, index) => (
            <PriceCard
              key={index}
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-once="false"
              data-aos-delay={index * 200}
            >
              <PriceHeader>
                <h3>{plan.sessions}</h3>
                <Price>{plan.price}</Price>
                {plan.pricePerSession ? (
                  <PriceSubtext>{plan.pricePerSession}</PriceSubtext>
                ) : (
                  <EmptySpace />
                )}
                {plan.savings ? (
                  <PriceSubtext>{plan.savings}</PriceSubtext>
                ) : (
                  <EmptySpace />
                )}
              </PriceHeader>
              <FeaturesList>
                {plan.features.map((feature, idx) => (
                  <Feature key={idx}>{feature}</Feature>
                ))}
              </FeaturesList>
              <ButtonWrapper>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <Button primary="true">Nous contacter</Button>
                </Link>
              </ButtonWrapper>
            </PriceCard>
          ))}
        </PricingGrid>
      </Container>
    </Section>
  );
};

export default InfoSection3;
