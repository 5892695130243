import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { menuData } from "../data/MenuData";
import { Button } from "./Button";
import Bars from "../images/bars.svg";

const Nav = styled.nav`
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  z-index: 100;
  position: fixed;
  width: 100%;
  transition: 0.4s;
`;

const NavLink = css`
  color: ${({ isScrolled, isHomePage, isDarkBackground }) => {
    if (isScrolled || !isHomePage) return "#2f6aa4";
    return isDarkBackground ? "#fff" : "#2f6aa4";
  }};
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.4s;
`;

const Logo = styled(Link)`
  ${NavLink}
  font-style: italic;
`;

const MenuBars = styled.i`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    background-image: url(${Bars});
    background-size: contain;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 25%);
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavMenuLinks = styled(Link)`
  ${NavLink}
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  @keyframes text-focus-in {
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Navbar = ({ toggle }) => {
  const [navbar, setNavbar] = useState(false);
  const [isDarkBackground, setIsDarkBackground] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const changeBackground = () => {
    if (window.pageYOffset >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    const watchScroll = () => {
      window.addEventListener("scroll", changeBackground);
    };

    watchScroll();

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  useEffect(() => {
    const checkBackgroundBrightness = () => {
      if (isHomePage && !navbar) {
        setIsDarkBackground(true);

        if (location.pathname === "/page-claire") {
          setIsDarkBackground(false);
        }
      }
    };

    checkBackgroundBrightness();
  }, [isHomePage, navbar, location]);

  let style = {
    backgroundColor: navbar || !isHomePage ? "#fff" : "transparent",
    transition: "0.4s",
  };

  return (
    <Nav style={style}>
      <Logo
        isScrolled={navbar}
        isHomePage={isHomePage}
        isDarkBackground={isDarkBackground}
      >
        <Link to="top" spy={true} smooth={true} offset={-200} duration={1400}>
          <strong>COACHING </strong>BEN
        </Link>
      </Logo>

      <MenuBars onClick={toggle} />
      <NavMenu>
        {menuData.map((item, index) => (
          <NavMenuLinks
            key={index}
            to={item.link}
            spy={true}
            smooth={true}
            offset={-30}
            duration={950}
            isScrolled={navbar}
            isHomePage={isHomePage}
            isDarkBackground={isDarkBackground}
          >
            {item.title}
          </NavMenuLinks>
        ))}
      </NavMenu>
      <NavBtn>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-100}
          duration={1500}
        >
          <Button primary="true">Me Contacter</Button>
        </Link>
      </NavBtn>
    </Nav>
  );
};

export default Navbar;
