export const TYPOGRAPHY = {
  h1: {
    size: "clamp(2.5rem, 8vw, 4rem)",
    weight: 700,
    lineHeight: 1.2,
  },
  h2: {
    size: "clamp(2rem, 6vw, 3rem)",
    weight: 600,
    lineHeight: 1.3,
  },
  h3: {
    size: "clamp(1.5rem, 4vw, 2rem)",
    weight: 600,
    lineHeight: 1.4,
  },
  body: {
    size: "clamp(1rem, 2vw, 1.2rem)",
    weight: 400,
    lineHeight: 1.6,
  },
  small: {
    size: "clamp(0.875rem, 1.5vw, 1rem)",
    weight: 400,
    lineHeight: 1.5,
  },
};
