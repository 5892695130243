import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { Button } from "./Button";
import { Link } from "react-scroll";
import { IoMdArrowRoundForward } from "react-icons/io";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";
import { TYPOGRAPHY } from "./shared/Typography";

const HeroSection = styled.section`
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const HeroSlider = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #000;
  overflow: hidden;
`;

const SlideTrack = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const HeroSlide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const HeroImage = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  will-change: transform, opacity;
  transform: ${({ isFirstSlide }) => (isFirstSlide ? "scaleX(-1)" : "none")};
`;

const ImagePreloader = styled.div`
  display: none;
`;

const HeroContent = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  width: 100%;
  color: #fff;
  left: ${({ isLastSlide }) => (isLastSlide ? "50%" : "50px")};
  top: ${({ isLastSlide }) => (isLastSlide ? "20%" : "50%")};
  transform: ${({ isLastSlide }) =>
    isLastSlide ? "translate(-50%, -50%)" : "translateY(-50%)"};
  margin-left: ${({ isLastSlide }) => (isLastSlide ? "0" : "6rem")};

  h1 {
    font-size: clamp(2.5rem, 8vw, 4rem);
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: ${({ isLastSlide }) =>
      isLastSlide ? "none" : "2px 2px 4px rgba(0, 0, 0, 0.3)"};
    margin-bottom: 1.5rem;
    letter-spacing: 0.5px;

    text-align: ${({ isLastSlide }) => (isLastSlide ? "center" : "left")};
    background: ${({ isLastSlide }) =>
      isLastSlide ? "linear-gradient(90deg, #2f6aa4, #4a8dc7)" : "transparent"};
    -webkit-background-clip: ${({ isLastSlide }) =>
      isLastSlide ? "text" : "none"};
    -webkit-text-fill-color: ${({ isLastSlide }) =>
      isLastSlide ? "transparent" : "#fff"};
  }

  p {
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    line-height: ${({ isLastSlide }) => (isLastSlide ? "1.5" : "2")};
    font-weight: 400;
    margin-bottom: ${({ isLastSlide }) => (isLastSlide ? "2rem" : "1.5rem")};
    max-width: ${({ isLastSlide }) => (isLastSlide ? "800px" : "600px")};
    text-shadow: ${({ isLastSlide }) =>
      isLastSlide ? "none" : "1px 1px 2px rgba(0, 0, 0, 0.2)"};
    opacity: 0.95;
    display: block;

    text-align: ${({ isLastSlide }) => (isLastSlide ? "center" : "left")};
    margin-left: ${({ isLastSlide }) => (isLastSlide ? "auto" : "0")};
    margin-right: ${({ isLastSlide }) => (isLastSlide ? "auto" : "0")};
    color: ${({ isLastSlide }) => (isLastSlide ? "#333" : "#fff")};
  }

  ${({ isLastSlide }) =>
    isLastSlide &&
    css`
      a {
        margin: 0 auto;
      }
    `}

  @media screen and (max-width: 768px) {
    left: ${({ isLastSlide }) => (isLastSlide ? "50%" : "20px")};
    width: ${({ isLastSlide }) => (isLastSlide ? "100%" : "calc(100% - 40px)")};
    top: ${({ isLastSlide }) => (isLastSlide ? "25%" : "50%")};
    transform: ${({ isLastSlide }) =>
      isLastSlide ? "translate(-50%, -50%)" : "translateY(-50%)"};
    margin-left: ${({ isLastSlide }) => (isLastSlide ? "0" : "1rem")};

    h1 {
      font-size: clamp(1.8rem, 6vw, 2.5rem);
      margin-bottom: 1rem;
    }

    p {
      font-size: clamp(0.9rem, 2vw, 1.1rem);
      line-height: ${({ isLastSlide }) => (isLastSlide ? "1.5" : "1.8")};
      margin-bottom: ${({ isLastSlide }) =>
        isLastSlide ? "1.5rem" : "1.2rem"};
    }
  }
`;

const Arrow = styled(IoMdArrowRoundForward)`
  margin-left: 0.5rem;
`;

const SliderButtons = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  display: flex;
  z-index: 10;

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: 20px;
  }
`;

const arrowButtons = css`
  width: 50px;
  height: 50px;
  color: #fff;
  cursor: pointer;
  background: #000d1a;
  border-radius: 50px;
  padding: 10px;
  margin-right: 1rem;
  user-select: none;
  transition: 0.3s;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  @keyframes text-focus-in {
    0% {
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      filter: blur(0px);
      opacity: 1;
    }
  }

  &:hover {
    background: #fff;
    transform: scale(1.05);
    color: #000d1a;
  }
`;

const PrevArrow = styled(IoArrowBack)`
  ${arrowButtons}
`;

const NextArrow = styled(IoArrowForward)`
  ${arrowButtons}
`;

const Hero = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState({});
  const length = slides.length;
  const timeout = useRef(null);
  const direction = useRef(1);

  useEffect(() => {
    slides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
      img.onload = () => {
        setImagesLoaded((prev) => ({ ...prev, [slide.image]: true }));
      };
    });
  }, [slides]);

  useEffect(() => {
    const nextSlide = () => {
      direction.current = 1;
      setCurrent((current + 1) % length);
    };

    timeout.current = setTimeout(nextSlide, 7000);
    return () => timeout.current && clearTimeout(timeout.current);
  }, [current, length]);

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 1,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? "100%" : "-100%",
      opacity: 1,
    }),
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection) => {
    direction.current = newDirection;
    if (newDirection === 1) {
      setCurrent((current + 1) % length);
    } else {
      setCurrent((current - 1 + length) % length);
    }
  };

  const nextSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    direction.current = 1;
    setCurrent((current + 1) % length);
  };

  const prevSlide = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    direction.current = -1;
    setCurrent((current - 1 + length) % length);
  };

  return (
    <HeroSection>
      <HeroWrapper name="top">
        <HeroSlider>
          <AnimatePresence initial={false} custom={direction.current}>
            <motion.div
              key={current}
              custom={direction.current}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "tween", duration: 0.8, ease: "easeInOut" },
              }}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <HeroImage
                src={slides[current].image}
                alt={slides[current].alt}
                isThirdSlide={current === 2}
                isFirstSlide={current === 0}
                style={{
                  opacity: imagesLoaded[slides[current].image] ? 1 : 0,
                }}
              />
              <HeroContent isLastSlide={current === length - 1}>
                <h1 data-aos="fade-down" data-aos-duration="600">
                  {slides[current].title}
                </h1>
                <p
                  data-aos="fade-down"
                  data-aos-duration="600"
                  data-aos-delay="200"
                >
                  {slides[current].datacoach1 && (
                    <>
                      {slides[current].datacoach1}
                      <br />
                    </>
                  )}
                  {slides[current].datacoach2 && (
                    <>
                      {slides[current].datacoach2}
                      <br />
                    </>
                  )}
                  {slides[current].datacoach3 && (
                    <>
                      {slides[current].datacoach3}
                      <br />
                    </>
                  )}
                  {slides[current].datacoach4 && (
                    <>
                      {slides[current].datacoach4}
                      {!current === length - 1 && <br />}
                    </>
                  )}
                </p>
                <Link
                  to={slides[current].path}
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={500}
                >
                  <Button
                    primary="true"
                    css={`
                      width: 160px;
                    `}
                  >
                    {slides[current].label}
                    <Arrow />
                  </Button>
                </Link>
              </HeroContent>
            </motion.div>
          </AnimatePresence>
        </HeroSlider>
        <SliderButtons>
          <PrevArrow onClick={prevSlide} />
          <NextArrow onClick={nextSlide} />
        </SliderButtons>
      </HeroWrapper>
    </HeroSection>
  );
};

export default Hero;
