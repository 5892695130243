import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Link } from "react-scroll";
import { TYPOGRAPHY } from "../shared/Typography";

const Section = styled.section`
  width: 100%;
  height: 100%;
  padding: 4rem 0rem;
  background: ${({ dark }) => (dark ? "#000" : "#fff")};
`;

const Container = styled.div`
  padding: 2rem calc((100vw - 1300px) / 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(600px, auto);
  gap: 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  line-height: 1.4;
  padding: 1rem 1rem;
  order: ${({ reverse }) => (reverse ? "2" : "1")};

  @media screen and (max-width: 768px) {
    padding: 0rem 1rem;
    margin: 0;
  }
  h1 {
    font-size: ${TYPOGRAPHY.h1.size};
    font-weight: ${TYPOGRAPHY.h1.weight};
    line-height: ${TYPOGRAPHY.h1.lineHeight};
    margin-bottom: 2rem;
    text-transform: uppercase;
    background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: ${TYPOGRAPHY.body.size};
    line-height: ${TYPOGRAPHY.body.lineHeight};
    margin-bottom: 1.5rem;
    color: ${({ dark }) => (dark ? "#fff" : "#333")};
  }
`;

const ColumnRight = styled.div`
  padding: 1rem 2rem;
  order: ${({ reverse }) => (reverse ? "1" : "2")};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    order: ${({ reverse }) => (reverse ? "2" : "1")};
    padding: 0;
    margin: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }

    @media screen and (max-width: 768px) {
      width: 90%;
      height: 90%;
    }
  }
`;

const StyledButton = styled(Button)`
  background: ${({ primary }) => (primary ? "#4A8DC7" : "#2F6AA4")};
  white-space: nowrap;
  outline: none;
  border: none;
  min-width: 120px;
  max-width: 220px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ big }) => (big ? "18px 44px" : "16px 28px")};
  color: ${({ primary }) => (primary ? "#fff" : "#000")};
  font-size: ${({ big }) => (big ? "22px" : "16px")};

  &:hover {
    transform: translateY(-2px);
  }
`;

const InfoSection = ({
  heading,
  paragraphOne,
  paragraphTwo,
  paragraphThree,
  buttonLabel,
  reverse,
  image,
  delay,
  name,
  goto,
  dark,
}) => {
  return (
    <Section dark={dark}>
      <Container name={name}>
        <ColumnLeft
          reverse={reverse}
          dark={dark}
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-once="true"
          data-aos-delay={delay}
          data-aos-anchor-placement="top-center"
        >
          <h1>{heading}</h1>
          <p>{paragraphOne}</p>
          <p>{paragraphTwo}</p>
          <p>{paragraphThree}</p>

          <Link to={goto} spy={true} smooth={true} offset={-70} duration={700}>
            <StyledButton primary="true">{buttonLabel}</StyledButton>
          </Link>
        </ColumnLeft>
        <ColumnRight reverse={reverse}>
          <img
            src={image}
            alt="home"
            data-aos="zoom-out"
            data-aos-duration="1200"
            data-aos-once="false"
            data-aos-delay={delay}
            data-aos-anchor-placement="center bottom"
          />
        </ColumnRight>
      </Container>
    </Section>
  );
};

export default InfoSection;
