"use client";

import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import HomeOne from "../images/home-1.jpg";
import HomeTwo from "../images/home-2.jpg";
import HomeThree from "../images/home-3.jpg";
import HomeFour from "../images/home-4.jpg";
import HomeFive from "../images/home-5.jpg";
import HomeSix from "../images/home-6.jpg";
import { TYPOGRAPHY } from "../shared/Typography";

const Section = styled.section`
  width: 100%;
  padding: 3rem 0;
  background: #fff;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const Title = styled.h2`
  font-size: ${TYPOGRAPHY.h1.size};
  font-weight: ${TYPOGRAPHY.h1.weight};
  line-height: ${TYPOGRAPHY.h1.lineHeight};
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  background: linear-gradient(90deg, #2f6aa4, #4a8dc7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${TYPOGRAPHY.body.size};
  line-height: ${TYPOGRAPHY.body.lineHeight};
  color: #333;
  margin-bottom: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled(motion.div)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.1);
  }
`;

const Gradient = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  color: white;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: ${TYPOGRAPHY.small.size};
  line-height: ${TYPOGRAPHY.small.lineHeight};
  margin-bottom: 0.3rem;
  color: white;
`;

const CardTitle = styled.h3`
  font-size: clamp(1.2rem, 3vw, 1.5rem);
  font-weight: ${TYPOGRAPHY.h3.weight};
  line-height: 1.3;
  margin-bottom: 0.4rem;
  color: white;
`;

const Description = styled.p`
  font-size: clamp(0.9rem, 1.5vw, 1.1rem);
  line-height: 1.4;
  opacity: 0.9;
  color: white;
`;

const MapPinIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
    <circle cx="12" cy="10" r="3" />
  </svg>
);

const Listings = () => {
  const sessions = [
    {
      image: HomeThree,
      title: "Apprentissage natation",
      location: "L'Isle sur Sorgue",
      description: "Cours de natation personnalisés pour tous niveaux",
    },
    {
      image: HomeTwo,
      title: "Coaching à domicile",
      location: "Carpentras",
      description: "Séances adaptées dans le confort de votre maison",
    },
    {
      image: HomeOne,
      title: "Small Group Training",
      location: "Loriol-du-Comtat",
      description: "Entraînement en petit groupe en extérieur",
    },
    {
      image: HomeFour,
      title: "Fitness en groupe",
      location: "Loriol-du-Comtat",
      description: "Sessions dynamiques et motivantes",
    },
    {
      image: HomeFive,
      title: "Cross Training",
      location: "L'Isle sur Sorgue",
      description: "Séances intensives et variées",
    },
    {
      image: HomeSix,
      title: "Group Fitness",
      location: "ADN Gym Carpentras",
      description: "Entraînement fonctionnel en groupe",
    },
  ];

  return (
    <Section name="listings" id="listings">
      <Container>
        <Header>
          <Title>Ils ont déjà commencé leur transformation</Title>
          <Subtitle>
            Découvrez les différentes séances et activités proposées pour
            atteindre vos objectifs
          </Subtitle>
        </Header>
        <Grid>
          {sessions.map((session, index) => (
            <Card
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ImageContainer>
                <Image src={session.image} alt={session.title} />
                <Gradient />
                <Content>
                  <Location>
                    <MapPinIcon />
                    <span>{session.location}</span>
                  </Location>
                  <CardTitle>{session.title}</CardTitle>
                  <Description>{session.description}</Description>
                </Content>
              </ImageContainer>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Listings;
