import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-scroll";
import { Button } from "./Button";
import { IoMdArrowRoundUp } from "react-icons/io";
import { FaInstagram, FaFacebookF } from "react-icons/fa";

const Section = styled.section`
  background: #2f6aa4;
  color: #fff;
  width: 100%;
  min-height: 600px;
  padding: 3rem calc((100vw - 1300px) / 2);
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4rem 0rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Quote = styled.div`
  flex: 1;
  padding: 2rem 0rem;

  h3 {
    font-size: clamp(2rem, 8vw, 5rem);
  }
`;

const FooterInfo = styled.div`
  padding: 2rem 1rem;
  line-height: 3;
  display: flex;
  flex-direction: column;

  a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 0rem;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  padding: 2rem 0rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  width: 50%;

  @media screen and (max-width: 768px) {
    margin-bottom: 2rem;
    width: 100%;
  }
`;

const Icons = css`
  font-size: clamp(1rem, 6vw, 2rem);
  margin-right: 1.5rem;
  color: #fff;
`;

const Instagram = styled(FaInstagram)`
  ${Icons}
`;

const Facebook = styled(FaFacebookF)`
  ${Icons}
`;

const Contact = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const Footer = () => {
  return (
    <Section id="footer" name="footer">
      <Container>
        <FooterTop>
          <Quote>
            <h3>
              Mon objectif :<br /> vous faire atteindre les vôtres !
            </h3>
          </Quote>
          <FooterInfo>
            <h4>Sommaire</h4>
            <Link
              to="cours1"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Qui suis-je
            </Link>
            <Link
              to="cours3"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Pourquoi ?
            </Link>
            <Link
              to="cours2"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
            >
              Pour qui ?
            </Link>
            <Link
              to="features"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Comment je travail ?
            </Link>
          </FooterInfo>
          <FooterInfo>
            <h4>
              J'interviens sur <br></br> Orange, Carpentras <br></br> et
              alentours (84).
            </h4>
          </FooterInfo>
        </FooterTop>
        <FooterBottom>
          <SocialIcons>
            <a
              href="https://www.instagram.com/coaching.ben/?hl=fr"
              rel="noopener noreferrer"
              target="https://www.instagram.com/coaching.ben/?hl=fr"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/coaching.ben"
              rel="noopener noreferrer"
              target="https://www.facebook.com/coaching.ben"
            >
              <Facebook />
            </a>
          </SocialIcons>
          <Contact>
            <Link
              to="top"
              spy={true}
              smooth={true}
              offset={-200}
              duration={1000}
            >
              <Button primary="true">
                Remonter <IoMdArrowRoundUp />
              </Button>
            </Link>
          </Contact>
        </FooterBottom>
      </Container>
    </Section>
  );
};

export default Footer;
